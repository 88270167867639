import { RefObject } from 'react';

import { isNotSSR } from '@utils';

export const scrollToRef = (ref: RefObject<HTMLDivElement | null>) => {
	if (ref.current && isNotSSR) {
		window.scrollTo({
			top: ref.current?.offsetTop - 20,
			behavior: 'smooth',
		});
	}
};
